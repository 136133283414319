import axios from 'axios';
import config from "../config";


function blQuery(params) {
    let body = {
        "jsonrpc": "2.0", 
        "method": params.method,
        "id": null 
    }
    if(params.params !== undefined) {
        body["params"] = params.params
    }

    return axios.post(
        config.apiURL ,
        body,
        { 
            headers: { 
                'Content-Type': 'application/json',
            },
            withCredentials: true
        }
    );
}

export default blQuery;