import { useContext } from "react";
import { AuthContext } from './context/Auth';


function App() {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="App">
      <div>
        <img src={currentUser.picture} alt="avatar"></img>
        <h3>{currentUser.name}</h3>
      </div>
    </div>
  );
}

export default App;
