import blQuery from "../clients/bl";
import config from "../config";
import { jwtDecode } from "jwt-decode";


function inMemoryJWTService() {
    let inMemoryJWT = null;
    let refreshTimeoutId = null;

    /**
     * Обработчик, который запускает по таймеру обновление для access-токена.
     * @param {*} expiration - метка timestamp в мс, когда токен нужно обновить
     */
    function refreshToken(expiration) {
        // обновляем за 10 сек до истечения срока действия
        let refrashDelay = expiration - Date.now() - 10000;
        if (refrashDelay < 0) {
            refrashDelay = 0;
        }

        refreshTimeoutId = setTimeout(() => {
            blQuery({
                method: 'CheckAuth',
            }).then((response) => {
                const accessToken = response.data.result;
                if (accessToken) {
                    const user_data = jwtDecode(accessToken);
                    setToken(accessToken, user_data.exp);
                } else {
                    deleteToken();
                }
            }).catch(console.error);
        }, refrashDelay);
    };

    function abortRefreshToken() {
        if (refreshTimeoutId) {
            clearTimeout(refreshTimeoutId);
        }
    };

    const getToken = () => inMemoryJWT;

    /**
     * Созранить jwt-токен
     * @param {*} token - jwt-токен
     * @param {*} tokenExpiration - метка timestamp в сек, когда срок действия токена истекает 
     */
    function setToken(token, tokenExpiration) {
        inMemoryJWT = token;
        refreshToken(tokenExpiration * 1000);
    };

    function deleteToken() {
        inMemoryJWT = null;
        abortRefreshToken();
        localStorage.setItem(config.LOGOUT_STORAGE_KEY, Date.now());
    };

    return {
        getToken,
        setToken,
        deleteToken,
    };
};

export default inMemoryJWTService();