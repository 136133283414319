import { createContext, useState, useEffect, useCallback, useMemo } from "react";
import blQuery from "../clients/bl";
import config from "../config";
import inMemoryJWT from "../services/inMemoryJWT";
import { jwtDecode } from "jwt-decode";
import showErrorMessage from "../utils/showErrorMessage";


export const AuthContext = createContext();


function Auth({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        blQuery({
            method: 'CheckAuth',
        }).then((response) => {
            if (response.data.result) {
                const accessToken = response.data.result;
                const user_data = jwtDecode(accessToken);
                inMemoryJWT.setToken(accessToken, user_data.exp);
                setCurrentUser(user_data);
                setIsUserLoggedIn(true);
            }
        }).catch(console.error);
    }, []);

    useEffect(() => {
        function handlePersistedLogOut(event) {
            if (event.key === config.LOGOUT_STORAGE_KEY) {
                inMemoryJWT.deleteToken();
                setCurrentUser();
                setIsUserLoggedIn(false);
            }
        };

        window.addEventListener("storage", handlePersistedLogOut);

        return () => {
            window.removeEventListener("storage", handlePersistedLogOut);
        };
    }, []);

    useEffect(() => {
        function handleCallbackResponse(response) {
            blQuery({
                method: 'GoogleOAuth2',
                params: response.credential
            }).then((response) => {
                if (response.data.result) {
                    const accessToken = response.data.result;
                    const user_data = jwtDecode(accessToken);
                    /* user_data = {
                        "aud": "99409559602-9i9p0vqdofkbfbs0ucr1m075pilpltbr.apps.googleusercontent.com",
                        "azp": "99409559602-9i9p0vqdofkbfbs0ucr1m075pilpltbr.apps.googleusercontent.com",
                        "email": "petrushov.nikolay@gmail.com",
                        "email_verified": true,
                        "exp": 1712413328,
                        "family_name": "Петрушов",
                        "given_name": "Николай",
                        "iat": 1712409728,
                        "iss": "https://accounts.google.com",
                        "jti": "978006aa311dc178d6c22557132463ccb17f814f",
                        "name": "Николай Петрушов",
                        "nbf": 1712409428,
                        "picture": "https://lh3.googleusercontent.com/a/ACg8ocLZKDPMJ63M0GVuKNrSLKxqvTA12r4glY7efZs_4xCrwwMpvA=s96-c",
                        "sub": "107449796723825200290"
                    }*/
                    inMemoryJWT.setToken(accessToken, user_data.exp);
                    setCurrentUser(user_data);
                    setIsUserLoggedIn(true);
                } else {
                    setCurrentUser();
                    setIsUserLoggedIn(false);
                }
            }).catch(() => {
                setCurrentUser();
                setIsUserLoggedIn(false);
            });
        }

        /* global google */
        google.accounts.id.initialize({
            client_id: config.goodleClientID,
            use_fedcm_for_prompt: true,
            callback: handleCallbackResponse
        });

        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: "outline", size: "large" }
        );

        google.accounts.id.prompt();
    }, []);

    const handleLogOut = useCallback(() => {
        blQuery({
            method: 'LogOut',
        }).then(() => {
            inMemoryJWT.deleteToken();
            setCurrentUser();
            setIsUserLoggedIn(false);
        }).catch(showErrorMessage);
    }, []);

    const authContextValue = useMemo(() => ({
        currentUser, handleLogOut
    }), [currentUser, handleLogOut]);

    return (
        <AuthContext.Provider value={authContextValue}>
            {isUserLoggedIn ? children :
                /*window.location.replace(config.authURL)*/
                <div id="signInDiv"></div>
            }
        </AuthContext.Provider>
    );
};

export default Auth;