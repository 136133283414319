const appURL = "https://account.hardowl.ru";
//const appURL = "http://localhost";

// appURL/some - page
// appURL/some/ - service
const cfg = {
    baseURL: appURL,
    authURL: appURL + "/auth",
    apiURL: appURL + "/jsonrpc/",
    goodleClientID: "99409559602-9i9p0vqdofkbfbs0ucr1m075pilpltbr.apps.googleusercontent.com",
    LOGOUT_STORAGE_KEY: "logout",
};

export default cfg;